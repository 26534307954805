var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"pa-6",attrs:{"color":"white"}},[_c('v-data-table',{attrs:{"single-expand":"","show-expand":"","loading":_vm.loading,"headers":_vm.headers,"items":_vm.transactions,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.id.slice(-5))+" ")]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.timestamp)))]}},{key:"item.transactionType",fn:function(ref){
var item = ref.item;
return [(item.transactionType === 'Withdrawal')?_c('v-chip',{staticClass:"red--text",attrs:{"small":"","color":"red lighten-4"}},[_vm._v("Withdrawal")]):_c('v-chip',{staticClass:"green--text",attrs:{"small":"","color":"green lighten-4"}},[_vm._v("Deposit")])]}},{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.user.firstName)+" "+_vm._s(item.user.lastName))]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.user.email))]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("currency")(item.amount)))]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.user.country))]}},{key:"item.cancelDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("date")(item.cancelDate)))]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"additional-row",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"additional-row-head-wrapper"},[_c('table',{staticStyle:{"width":"100%"}},[_c('thead',[_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("User ID")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Provider")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Affiliate ID")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Banner ID")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Campaign ID")])]),_c('td',{staticClass:"font-weight-bold"},[_c('p',[_vm._v("Agent")])])])]),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(item.user.id.slice(-5)))]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',[_vm._v(_vm._s(_vm.getAgentNameById(item.agentId)))])])])])])])]}}],null,true)}),_c('div',{staticClass:"pagination-wrapper"},[_c('button',{staticClass:"pagination_btn pagination-btn--previous",attrs:{"disabled":_vm.currentPage === 1},on:{"click":function($event){return _vm.prev()}}},[_c('v-icon',{staticClass:"pagination_btn-icon mr-2"},[_vm._v("mdi-chevron-left")]),_vm._v("Previous ")],1),_c('p',{staticClass:"pagination_total-page"},[_vm._v("Page "+_vm._s(_vm.currentPage)+" of "+_vm._s(_vm.totalPages)+" ("+_vm._s(_vm.totalTransactions)+" results)")]),_c('button',{staticClass:"pagination_btn pagination-btn--next",attrs:{"disabled":_vm.currentPage === _vm.totalPages || _vm.currentPage > _vm.totalPages},on:{"click":function($event){return _vm.next()}}},[_vm._v(" Next"),_c('v-icon',{staticClass:"pagination_btn-icon ml-2"},[_vm._v("mdi-chevron-right")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }